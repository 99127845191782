interface Props {
  children: JSX.Element;
}

export default function HomeStatistics({ children }: Props) {
  return (
    <div className="bg-safety-orange sm:rounded-md h-32 sm:h-44 text-alice-blue">
      {children}
    </div>
  );
}
